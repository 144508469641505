.container {
  background-color: #1e1e26;
  display: grid;
  grid-template-columns: 10rem 1fr 11rem;
  grid-template-areas: 'title types usage';
  grid-template-rows: 3.75rem;
  border-radius: 1.25rem;
  position: relative;
  justify-content: space-between;

  @media (max-width: 930px) {
    grid-template-areas: 'title usage'
      'types types';
    justify-content: space-between;
    align-items: center;
    grid-template-columns: 10rem 11rem;
    grid-template-rows: 3.75rem 3.75rem;
  }

  @media (max-width: 800px) {
    border-radius: 0.625rem;
  }

  @media (max-width: 450px) {
    grid-template-areas: 'title'
      'usage'
      'types';
      justify-content: center;
    align-items: center;
    grid-template-columns: 1fr;
    grid-template-rows: 3rem 3rem 3rem;
  }

  .nameProject {
    grid-area: title;
    background: #252532;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0rem 1.875rem;
    border-radius: 1.25rem;
    height: 100%;
    white-space: nowrap;

    @media (max-width: 800px) {
      border-radius: 0.625rem;
      padding: 0rem 0.875rem;
      font-size: 0.8125rem;
      font-weight: 600;
    }

    @media (max-width: 450px) {
      border-radius: 0;
      border-top-left-radius: 0.625rem;
      border-top-right-radius: 0.625rem;
    }
  }

  .nameMusicTypesUsage {
    grid-area: types;
    display: flex;
    align-items: center;
    padding: 1rem;
    justify-content: center;

    @media (max-width: 800px) {
      padding: 0rem 0.35rem;
    }

    @media (max-width: 525px) {
      justify-content: start;
      overflow-x: auto;
      white-space: nowrap; 
    }
  }

  .projectActions {
    align-self: end;
    grid-area: usage;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.375rem;
    height: 100%;
    padding: 0rem 0.875rem;
    background-color: #252532;
    border-radius: 1.25rem;

    @media (max-width: 800px) {
      border-radius: 0.625rem;
      padding: 0rem 0.5rem;
    }
    @media (max-width: 450px) {
      border-radius: 0;
    }
  }
}

.iconSetting {
  display: flex;
  align-items: center;
  gap: 0.3125rem;
}

.whiteFont {
  margin: 0 0.15625rem;
  color: #FFFFFF;
}

.defaultColor {
  margin: 0 0.15625rem;
  color: #716C95;
}

.deleteAction {
  cursor: pointer;
}
