.playButton {
    position: fixed;
    bottom: 4.0625rem;
    left: 50%;
    transform: translate(-50%, 50%);
    padding: 0.3125rem 2.875rem;
    width: fit-content;
    border-radius: 2.5rem;
    background: rgba(56, 54, 76, 0.40);
    backdrop-filter: blur(0.9375rem);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.78125rem;
    box-sizing: border-box;
    z-index: 10000;
    max-height: 5rem;
    height: 100%;

    @media (max-width: 800px) {
        max-height: 4.375rem;
        gap: 0.46875rem;
        padding: 0.3125rem 1.25rem;
    }

    @media (max-width: 500px) {
        padding: 0.3125rem 0.9375rem;
    }

    @media (max-width: 930px) {
        padding: 0.3125rem 0.625rem;
        max-width: 15.625rem;
        gap: 0.3125rem;
    }

    @media (max-width: 400px) {
        width: 100%;
        max-width: 14.375rem;
        padding: 0.3125rem 0.625rem;
        margin-left: -0.3125rem;
    }

    .stop {
        width: 2.5rem;
        height: 2.5rem;

        @media (max-width: 500px) {
            width: 2rem;
            height: 2rem;
        }
    }

    .play {
        width: 4rem;
        height: 4rem;
        
        @media (max-width: 500px) {
            width: 3.5rem;
            height: 3.5rem;
        }
    }

    .volumeChange {
        width: 2.25rem;
        height: 2.25rem;

        @media (max-width: 500px) {
            width: 1.75rem;
            height: 1.75rem;
        }
    }

    .up {
        .up_settings {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 5rem;
        }
    }

    .icon {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    .icons {
        width: 2.5625rem;
        height: 2.5625rem;
        display: flex;
        align-items: center;
        margin-left: 0.625rem;

        @media (max-width: 500px) {
            width: 2.03125rem;
            height: 2.03125rem;
        }
    }

    .sliderStyles {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 5rem;
        height: 0.1875rem;
        margin-right: 0.625rem;

        @media (max-width: 930px) {
            max-width: 3.5rem;
        }
    }
}
