.container {
  .pageTitle {
    font-weight: 500;
    font-size: 1.5rem;

    @media (max-width: 550px) {
      margin-left: 0.9375rem;
    }
  }

  .sectionTitle {
    font-weight: 500;
    font-size: 1.25rem;
  }

  .projectsSection {
    margin-top: 2rem;
    width: 100%;

    @media (max-width: 800px) {
      margin-top: 1.25rem;
    }

    .projectsSectionList {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1.25rem;

      @media (max-width: 1750px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }

  .userInfo {
    margin: 1.25rem 0 2.5rem 0;
    display: flex;
    align-items: center;
    gap: 0.375rem;
  }
}
