@import "~/src/styles/defaults";

.music {
  border: 2px solid rgba(37, 37, 50, 0.5);
  border-radius: 30px;
  padding: 1.25rem 1.25rem 1.25rem 1.25rem;
  margin-bottom: 3.125rem;

  @media (max-width: 400px) {
    border-radius: 0.9375rem;
    padding: 0.5rem;
  }

  .music__settings {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 1rem;
    gap: 1rem;

    @media (max-width:900px) {
      margin-bottom: 1rem;
    }

    .track__settings {
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;

      @media (max-width:900px) {
        gap: 0.625rem;
      }
    }

    .mobileMenu {
      .showButtonSettings {
        width: 2.5rem;
        height: 2.5rem;
        background-color: #252532;
        border-radius: 0.75rem;
        cursor: pointer;
      }

      @media (min-width: 1000px) {
        display: none;
      }
    }

    .bundling__settings {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;

      @media (max-width: 650px) {
        flex-direction: column;
        gap: 1.25rem;
      }

      @media (max-width: 1000px) {
        display: none;
      }
    }
  }

  .kicks {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.8rem;

    @media (max-width:900px) {
      gap: 1rem;
    }

    .addKick {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.6rem;

      .addKick__icon {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .addKick__text {
        font-size: 1rem;
        font-weight: 500;
      }
    }
  }

  .kick__soundSettings {
    margin-top: 2.5rem;
    display: flex;
    
    justify-content: center;
    align-items: center;

    box-sizing: border-box;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 4rem;
    padding: 1rem 0.3rem;
    border-radius: 12px;
    background-color: #1e1e26 ;

    .kick__mainSlider {
      display: flex;
      width: 100%;
      gap: 1.25rem;
      padding: 1rem;
      @media (max-width: 1250px) {
        gap: 1.875rem;
      }

      .soundSeparator {
        position: relative;
        flex-grow: 1;
        display: flex;
        align-items: center;
        background: rgba(37, 37, 50, 0.40);
        justify-content: space-between;
        border-radius: 1.875rem;

        .soundSeparator__kickTitle {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 600;
          font-size: 1.5625rem;
          width: 4.375rem;
          height: 4.375rem;
          border-radius: 50%;
          z-index: 1;

          @media (max-width: 850px) {
            width: 3.125rem;
            height: 3.125rem;
          }
        }
      }

      .fx {
        max-width: 19.6875rem;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 1.25rem 0;
        margin-left: 2rem;
        background-color: hsla(240, 15%, 12%, 1);

        .fx__title {
          font-weight: 600;
          font-size: 1rem;
        }
      }
    }

    .kick__effects {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 2rem;

      .kick__effectAdding {
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: start;
        gap: 1.5rem;

        .kick__effectsTitle {
          padding-top: 1rem;
          align-self: flex-start;
          color: #747198;
          padding: 1rem;
          font-size: 1rem;
          font-weight: 500;
        }
      }

      .kick__effectsItems {
        color: #FFFFFF;
        background-color: #252532;
      }

      .kick_effectsList {
        display: flex;
        padding: 0.8rem;
        width: 100%;
        max-width: 6rem;
        flex-direction: column;

        .kick_effectsListText {
          line-height: 1.2rem;
          text-align: left;
          font-size: 0.8rem;
          font-weight: 400;
          color: #ffffff;
        }
      }

      .kick__effectsListItems {
        position: relative;
        border-radius: 2rem;
        overflow: hidden;
        background: linear-gradient(95deg, #0ad1ce 0%, #14c156 100%);
        border: 1px solid transparent;

        &::before {
          z-index: -1;
          content: "";
          position: absolute;
          top: 1px;
          bottom: 1px;
          left: 1px;
          right: 1px;
          border-radius: 1rem;
          background: #1a1a23;
        }

        .kick__effectsItem {
          color: white;
          list-style-type: none;
          padding: 0.2rem 0.8rem;
          .kick__effectsItemText {
            color: white;
          }
        }
      }

      .effects_container {
        display: grid;
        padding: 1rem;
        grid-template-columns: repeat(5, 18.5rem);
        gap: 0.8rem;

        @media(max-width:1440px){
          grid-template-columns: repeat(4, 20rem);
        }
        @media(max-width:1050px){
          grid-template-columns: repeat(3, 20rem);
        }
        @media(max-width:810px){
          grid-template-columns: repeat(2, 20rem);
        }
        @media(max-width:574px){
          grid-template-columns: repeat(1, 100%);
        }
      }
      

      .kick__effectsButton {
        color: #ffffff;
        background-color: #252532;
        font-size: 1rem;
        white-space: nowrap;
        font-weight: 500;
      }
    }
  }
}

.icon{
 margin: 0 3px 0 0;
}

.iconSetting {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  @media(max-width:650px) {
    font-size: 0.7rem;
  }
}

.containerSettings {
  display: flex;
  background-color: #1e1e26;
  padding: 0.5rem;
  border-radius: 1rem;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  gap: 1rem;

  @media (min-width: 1000px) {
    display: none;
  }
}


.effectsVisibleToggler{
  display: flex;
  align-items: center;
  width:fit-content;
  .effectsLabel{
    @media(max-width: 650px){
      display: none;
    }
  }
}


.kick__effectsListItemsList{
  border-radius: 1.4rem 1.4rem 1rem 1rem;
  overflow: hidden;
  background: linear-gradient(95deg, #0ad1ce 0%, #14c156 100%);
  border: 1px solid transparent;


  &::before {
    z-index: -1;
    content: "";
    position: absolute;
    top: 1px;
    bottom: 1px;
    left: 1px;
    right: 1px;
    border-radius: 1rem;
    background: #1a1a23;
  }

  .kick__effectsItem {
    color: white;
    list-style-type: none;
    padding: 0.2rem 0.8rem;
    .kick__effectsItemText {
      color: white;
    }
  }
}

