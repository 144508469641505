.effect {
  width: 100%;
  height: 13.8rem;
  padding: 1rem;
  border-radius: 0.75rem;
  gap: 1rem;
  background: #252532;

  @media(max-width:1550px ){
    height: 15rem;
  }

  .effectSettings {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .effectTitle {
          color: #FFF;
          font-weight: 500;
      }
  }
  .effectType {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .effectTypeName {
          color: rgba(194, 188, 255, 0.50);
          font-weight: 500;
          align-self: flex-start;
          margin: 1rem 0;
      }
      .slider {
          max-width: 12rem;
          margin: 0;
      }
  }
}


.kick__effects {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .kick__effectAdding {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: start;
    gap: 1.5rem;

    .kick__effectsTitle {
      padding-top: 1rem;
      align-self: flex-start;
      color: #747198;
      font-size: 1rem;
      font-weight: 500;
    }
  }

  .kick__effectsItems {
    color: #FFFFFF;
    background-color: #252532;
  }

  .kick_effectsList {
    display: flex;
    padding: 0.8rem;
    width: 100%;
    max-width: 6rem;
    flex-direction: column;

    .kick_effectsListText {
      line-height: 1.2rem;
      text-align: left;
      font-size: 0.8rem;
      font-weight: 400;
      color: #ffffff;
    }
  }

  .kick__effectsListItems {
    position: relative;
    border-radius: 5rem;
    overflow: hidden;
    background: linear-gradient(95deg, #0ad1ce 0%, #14c156 100%);
    border: 1px solid transparent;

    &::before {
      z-index: -1;
      content: "";
      position: absolute;
      top: 1px;
      bottom: 1px;
      left: 1px;
      right: 1px;
      border-radius: 5rem;
      background: #1a1a23;
    }

    .kick__effectsItem {
      color: white;
      list-style-type: none;
      padding: 0.2rem 0.8rem;
      .kick__effectsItemText {
        color: white;
      }
    }
  }

  .effects_container {
    display: grid;
    grid-template-columns: repeat(5, 20rem);
    gap: 0.9rem;

    @media(max-width:1440px){
      grid-template-columns: repeat(4, 20rem);
    }
    @media(max-width:1050px){
      grid-template-columns: repeat(3, 20rem);
    }
    @media(max-width:810px){
      grid-template-columns: repeat(2, 20rem);
    }
    @media(max-width:574px){
      grid-template-columns: repeat(1, 100%);
    }
  }
  

  .kick__effectsButton {
    color: #ffffff;
    background-color: #252532;

    font-size: 1rem;
    font-weight: 500;
  }
}

.kick__effectsItems {
  color: #FFFFFF;
  background-color: #252532;
}
.kick__effectsItem {
  color: white;
  list-style-type: none;
  padding: 0.2rem 0.8rem;
  .kick__effectsItemText {
    color: white;
  }
}

.kick__effectsListItems {
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
  background: linear-gradient(95deg, #0ad1ce 0%, #14c156 100%);
  border: 1px solid transparent;

  &::before {
    z-index: -1;
    content: "";
    position: absolute;
    top: 1px;
    bottom: 1px;
    left: 1px;
    right: 1px;
    border-radius: 1rem;
    background: #1a1a23;
  }

  .kick__effectsItem {
    color: white;
    list-style-type: none;
    padding: 0.2rem 0.8rem;
    .kick__effectsItemText {
      color: white;
    }
  }
}
.kick__effectsButton {
      color: #ffffff;
      background-color: #252532;
      display: flex;
      width: 10rem;
      justify-content: center;
      align-items: center;
      font-size: 1rem;
      font-weight: 500;
    }


    .iconSetting {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
    
      @media(max-width:650px) {
        font-size: 0.7rem;
      }
    }

    .kick__effectsItem.active {
      background:linear-gradient(95deg, #0ad1ce 0%, #14c156 100%);
  }
  