.layout {
  display: grid;
  grid-template-columns: 8.25rem 1fr;
  height: var(--height, 100vh);

  @media (max-width: 550px) {
    grid-template-columns: 1fr;
  }

  .outletBlur {
    pointer-events: none;
    filter: blur(2px);
  }
}

.toastifyContainer {
  position: absolute;
}