@import "variables";

:root{
  --main-color: hsla(240, 15%, 12%, 1);
  --darker: hsla(240, 14%, 10%, 1);
  --contrast: hsla(249, 16%, 24%, 1);

  --gray-212: hsla(212, 38%, 82%, 0.2);
  --gray-247: hsla(247, 13%, 62%, 1);
  --gray-250: hsla(245, 10%, 26%, 1);
  --gray-250: hsla(250, 3%, 38%, 1);
  --gray-pure: hsla(0, 0%, 85%, 1);

  --pink: hsla(342, 100%, 57%, 1);
  --purple: hsla(248, 55%, 53%, 1);
  --white: hsla(0, 0%, 100%, 1);
}

/* ---nullification--- */
* {
  padding: 0;
  margin: 0;
  border: 0;
}
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
:focus,
:active {
  outline: none;
}
a:focus,
a:active {
  outline: none;
  -webkit-tap-highlight-color: transparent;
}
nav,
footer,
header,
aside {
  display: block;
}
html,
body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-family: "Montserrat", sans-serif;
  background-color: var(--main-color);
  color: var(--white);
  font-size: 16px;
  overflow: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;::-webkit-scrollbar {
    width: 0;
  }

  @media (max-width: 1800px) {
    font-size: 14px;
  }
  @media (max-width: 1700px) {
    font-size: 13px;
  }
  @media (max-width: $bLaptop) {
    font-size: 12px;
  }
  @media (max-width: $laptop) {
    font-size: 11px;
  }
  @media (max-width: $bTablet) {
    font-size: 11px;
  }
  @media (max-width: $ssTablet) {
    font-size: 11px;
  }
  @media (max-width: 340px) {
    font-size: 10px;
  }
}

// ::-webkit-scrollbar {
//   display: none;
//   width: 0px; 
// }

// ::-webkit-scrollbar-track {
//   background: transparent; 
// }

// ::-webkit-scrollbar-thumb {
//   background: linear-gradient(to top, hsla(177, 100%, 35%, 1), hsla(158, 80%, 37%, 1));
//   border-radius: 30px; 
// }


input,
button,
textarea {
  font-family: inherit;
  -webkit-tap-highlight-color: transparent;
}
input::-ms-clear {
  display: none;
}
button {
  cursor: pointer;
  background-color: transparent;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
a,
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
ul li {
  list-style: none;
}
img {
  vertical-align: top;
  user-select: none;
  -webkit-user-drag: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: 400;
}



/* ---end nullification--- */

.fill{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fill-contain{
  width: 100%;
  height: 100%;
  object-fit: contain;
}


