.container {
  padding: 2.5rem;

  @media(max-width: 600px) {
    padding: 1rem;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo {
      width: 3.75rem;
      height: 2.4rem;

      @media(max-width: 800px) {
        width: 3rem;
        height: 2.4rem;
      }

      @media(max-width: 600px) {
        width: 2.5rem;
        height: 1.6rem;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .authorizationActions {
      display: flex;
      align-items: center;
      gap: 1.25rem;

      @media(max-width: 450px) {
        gap: 0.5rem;
      }
    }
  }

  .registrationForm {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    text-align: center;

    .registrationFormTitle {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 1rem;
      
    }
    .registrationFormRecoveryPassword {
      font-size: 1rem;
      line-height: 1.4rem;
      font-weight: 400;
      margin-bottom: 3rem;
    }
  }

  .formContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;

    .signIn {
      width: 100%;
      max-width: 18.75rem;
      padding: 0.1rem 0;
      border-radius: 0.3rem;
    }

    .formInput {
      background-color: #252532;
      color: #9f9f9f;
      border-radius: 0.375rem;
      padding: 1rem 1.25rem;
      width: 100%;
      max-width: 18.75rem;
      font-size: 0.875rem;
      font-weight: 400;
      &::placeholder{
        color:#9F9F9F
      }
    }
  }

  .socialMediaContainer {
    .socialMediaTitle {
      display: flex;
      width: 100%;
      align-items: center;
      margin: 2.5rem 0;
      justify-content: center;
      white-space: nowrap;

      .lineFirst {
        height: 1px;
        width: 3rem;
        display: flex;
        margin: 0 3rem 0 0.2rem;
        align-items: center;
        background-color: #ffffff;
      }
      .lineSecond {
        height: 1px;
        width: 3rem;
        display: flex;
        margin: 0 0.2rem 0 3rem;
        align-items: center;
        background-color: #ffffff;
      }
    }

    .socialMediaContent {
      margin-top: 1.25rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .socialMedias {
        display: grid;
        gap: 0.625rem;
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

.separation {
  width: 0.0625rem;
  height: 1.25rem;
  background-color: #ffffff;
}

.linkPassword {
  color: #0bcec6;
  transition: 200ms ease-in-out;
  &:hover {
    color: #98FFFB;
  }
}

.iconSetting {
  display: flex;
  align-items: center;
  gap: 0.625rem;
}

.zIndex {
  z-index: 101;
}

.magiclyMediaIconWrapper {
  width: 1.25rem;
  height: 0.8rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}