.kick {
    display: flex;
    width: 100%;
    background: rgba(255, 255, 255, 0.02);
    border-radius: 1.5rem;
    overflow: hidden;
    height: fit-content;
    @media (max-width: 1350px) {
        flex-direction: column;
    }
    @media (max-width: 900px) {
        border-radius: 0.625rem;
    }
    .mobileAdapty {
        display: flex;
        width: 100%;
        @media (max-width: 700px) {
            flex-direction: column;
        }
    }
    .kick__title {
        position: relative;
        max-width: 8.75rem;
        width: 100%;
        border-radius: 1.25rem;
        font-weight: 500;
        font-size: 0.9375rem;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 1350px) {
            border-radius: 0;
            border-top-left-radius: 1.25rem;
        }
        @media (max-width: 900px) {
            max-width: 6.25rem;
            font-size: 0.7rem;
            border-top-left-radius: 0.625rem;
        }
        @media (max-width: 700px) {
            max-width: 100%;
            padding: 0.3rem;
        }

        button {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
        }
    }
    .kick__sound {
        position: relative;
        width: 100%;
        button {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
        }
    }
    .kick__settings {
        max-width: 25rem;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 1rem;
        background: #252532;
        border-radius: 1.25rem;
        gap: 1rem;
        padding-right: 2rem;

        @media (max-width: 1350px) {
            max-width: 100%;
            border-radius: 0;
            border-bottom-left-radius: 1.25rem;
            border-bottom-right-radius: 1.25rem;
            padding: 0.5rem 1rem;
        }
        @media (max-width: 900px) {
            border-bottom-left-radius: 0.625rem;
            border-bottom-right-radius: 0.625rem;
        }
        @media (max-width: 400px) {
            padding: 0.3rem;
            padding-right: 1rem;
        }

        .kick__changing {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            .randomizeButton {
                height: 2.875rem;
            }
        }
        .playingChange {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
        }
        .settings__icons {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0.5rem;
            .volumeSlider {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 0.5rem;
                .settings__iconVolume {
                    width: 1rem;
                    height: 1rem;
                    .icon {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .slider {
                    width: 3.5rem;

                    @media (max-width: 1350px) {
                        width: 5rem;
                    }
                    @media (max-width: 700px) {
                        width: 3rem;
                    }
                }
            }
            .settings__iconPlay {
                width: 2.8125rem;
                height: 2.8125rem;
                @media (max-width: 400px) {
                    width: 2rem;
                    height: 2rem;
                }
                .icon {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}


.waves {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}

.kick.muted {
    opacity: 0.3;
    pointer-events: none;
}

.container {
    width: 100%;
}