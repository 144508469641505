.livePage {
  position: relative;


  .fixed {
    background-color: var(--main-color);
    z-index: 11;
    position: fixed;
    width: calc(100% - 8.25rem);
    right: 0;
    padding: 1.5rem 2.5rem;
    top: 0;

    @media(max-width: 900px) {
      padding: 1.25rem;
    }

    @media(max-width:550px) {
      padding: 0.9375rem;
      width: 100%;
    }

    @media(max-width:440px) {
      padding: 0.625rem;
    }


    .possibilities {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.9375rem;

      @media(max-width: 450px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .possibilities__title {
        font-weight: 500;
        font-size: 1.5rem;
        white-space: nowrap;
        color: #ffffff;

        @media (min-width: 450px) and (max-width: 550px) {
          margin-left: 2rem;
        }


        @media(max-width: 700px) {
          font-size: 1.2rem;
        }

        .possibilities__titleInput {
          font-weight: 500;
          font-size: 1.5rem;
          margin-bottom: 0.1rem;
          white-space: nowrap;
          color: #ffffff;
          background-color: transparent;

          @media(max-width: 700px) {
            font-size: 1.2rem;
          }

          @media(max-width: 450px) {
            max-width: 9.5rem;
          }
        }

        .titleEdit {
          text-align: center;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.625rem;
        }
      }

      .possibilities__settings {
        display: flex;
        align-items: center;
        gap: 1rem;

        @media(max-width: 450px) {
          padding: 0 0.5rem 0 0;
          gap: 0.5rem;
        }

        .separation {
          height: 1.25rem;
          width: 0.0625rem;
          background-color: #ffffff;
        }
      }
    }
  }
}

.container {
  margin: 2.5rem;
  position: relative;

  @media(max-width:450px) {
    margin: 0.625rem;
  } 
  @media(max-width: 900px) {
    margin: 2.5rem 1.25rem;
  }

  .categories {
    margin-bottom: 7rem;
    margin-top: 6rem;

    @media(max-width:450px) {
      margin-top: 8rem;
    }
  }
}

.pressPlus {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  user-select: none;

  .pressPlusText {
    color: #b2acdc;
    font-weight: 400;
    font-size: 1.25rem;
    white-space: nowrap;
  }
}

.settingIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 0.5rem;
}

.createNewTrack {
  user-select: none;
  position: fixed;
  margin: 2.0625rem;
  right: 0;
  bottom: 0;
  z-index: 100;

  @media(max-width: 930px) {
    margin: 2.0625rem 0.625rem;
  }

  @media(max-width: 450px) {
    margin: 2.8125rem 0.625rem;
  }

  .addingNewTrack {
    width: 3.75rem;
    height: 3.75rem;
    background: linear-gradient(to right, #0ad1ce, #14c156);
    border-radius: 100%;

    @media(max-width:450px) {
      width: 2.5rem;
      height: 2.5rem;
    }


    .plusIcon {
      color: white;
      font-size: 2.5rem;
      transition: transform 0.2s ease-in-out;

      @media(max-width: 450px) {
        font-size: 1.875rem;
      }
    }

    .rotate {
      transform: rotate(45deg);
    }
  }

  .musicAddingModal {
    position: absolute;
    z-index: 100;
    width: 11.25rem;
    margin-right: 3.125rem;
    border-top-right-radius: 0.9375rem;
    border-top-left-radius: 0.9375rem;
    border-bottom-left-radius: 0.9375rem;
    border-bottom-right-radius: 2.5rem;
    bottom: 3.4375rem;
    right: 0;
    padding: 1rem 0.75rem;
    height: fit-content;
    background-color: #252532;
    border: 1px solid #0bd0c2;

    @media(max-width:730px) {
      width: 9.375rem;
    }

    @media(max-width:650px) {
      margin-right: 0.625rem;
      bottom: 4.0625rem;
    }

    .musicAddingModal__title {
      z-index: 100;
      margin: 0.375rem;
      font-weight: 500;
      user-select: none;
    }

    .addCategoryButton {
      transition: 0.2s ease-out;
      color: #716c95;

      &:hover {
        color: #ffffff;
      }
    }
  }
}

.separationFirst {
  height: 1.25rem;
  width: 0.0625rem;
  background-color: #ffffff;

  @media(max-width: 1250px) {
    display: none;
  }
}

.containerSettings {
  display: flex;
  align-items: center;
  gap: 1.25rem;
}

.showButtonSettings {
  width: 2.5rem;
  height: 2.5rem;
  background-color: #252532;
  border-radius: 0.75rem;
  cursor: pointer;
}

.settingsMenu {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background-color: #1e1e26;
  margin-top: 1rem;
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  width: fit-content;

  @media (max-width: 760px) {
    padding: 0.5rem;
  }

  @media (max-width: 650px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.4rem;
    padding: 0.5rem;
  }
}

.separationMenu {
  height: 1.5rem;
  width: 100%;
  max-width: 0.0625rem;
  background-color: #ffffff;

  @media(max-width: 650px) {
    display: none;
  }
}